<script>
import { user } from "@/config/api/users";
import { required, email } from "vuelidate/lib/validators";

export default {
  data() {
    return {
      email: "",
      submitted: false,
      submitting: false,
      error: null,
      tryingToReset: false,
      isResetError: false,
      values: {},
      schema: [
        {
          type: "password",
          name: "password",
          label: "Enter a new password",
          validation: "required",
        },
        {
          type: "password",
          name: "password_confirm",
          label: "Confirm your password",
          validation: "^required|confirm:password",
          validationName: "Password confirmation",
        },
      ],
    };
  },
  validations: {
    email: { required, email },
  },
  created() {
    document.body.classList.add("auth-body-bg");
  },
  methods: {
    async submitHandler(data) {
      const api = user.resetUserPassword;
      api.data = {
        reset_token: this.$route.params.id,
        new_password: data.password,
      };
      this.submitting = true;
      this.generateAPI(api)
        .then((res) => {
          console.log(res.data);
          // this.$bvToast.toast("Password reset success", {
          //   title: "Password reset",
          //   variant: "success",
          //   solid: true,
          // });
          this.$router.push({ name: "resetSucess" });
        })
        .catch((err) => {
          this.$bvToast.toast(err.response.data.error, {
            title: "Password reset",
            variant: "danger",
            solid: true,
          });
        })
        .finally(() => {
          this.submitting = false;
        });
    },
  },
};
</script>

<template>
  <div>
    <div class="home-btn d-none d-sm-block">
      <a href="/">
        <i class="mdi mdi-home-variant h2 text-white"></i>
      </a>
    </div>
    <div>
      <div class="container-fluid p-0">
        <div class="row no-gutters">
          <div class="col-12">
            <div
              class="
                authentication-page-content
                p-4
                d-flex
                align-items-center
                min-vh-100
              "
              style="max-width: 548px; margin: auto"
            >
              <div class="w-100">
                <div class="row justify-content-center">
                  <div class="col-lg-9">
                    <div>
                      <div class="text-center">
                        <h4 class="font-size-18 mt-4">Reset Password</h4>
                        <p class="text-muted">Reset your password to B-SEEN.</p>
                      </div>

                      <div class="row">
                        <div
                          class="col-12"
                          style="max-width: 320px; margin: auto"
                        >
                          <FormulateForm
                            @submit="submitHandler"
                            v-model="values"
                            :schema="schema"
                          >
                            <FormulateInput
                            class="text-right"
                              type="submit"
                              :disabled="submitting"
                              :label="
                                submitting ? 'Changing...' : 'Change'
                              "
                            />
                          </FormulateForm>
                        </div>
                      </div>

                      <div class="mt-5 text-center">
                        <p>
                          Already know password ?
                          <router-link
                            tag="a"
                            to="/login"
                            class="font-weight-medium text-primary"
                            >Log in</router-link
                          >
                        </p>
                        <p>© 2022 B-SEEN</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>